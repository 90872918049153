/*
 * @Author: Gang Jiang 
 * @Date: 2024-12-09 14:00:26 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-01-07 09:43:55
 */

 <template>
  <div class="filters" ref="filtersWrapper">
    <a-popover
      trigger="click"
      placement="bottom"
      v-model:open="visible"
      overlayClassName="filters-popover"
      :getPopupContainer="() => $refs.filtersWrapper"
    >
      <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filtersNum"
        showDownIcon="true"
        >Filters
      </artmisads-select-button>
      <template #content>
        <div class="filters-down" ref="filtersDown">
          <div class="filters-name">Marketplace</div>
          <artmisads-select
            :width="200"
            class="country"
            :dropdownMatchSelectWidth="false"
            v-model:value="filters.countryCode"
            @change="setCountry($event)"
            :getPopupContainer="() => $refs.filtersWrapper"
          >
            <a-select-option
              class="country-select-item"
              key="all"
              value="All"
            />
            <a-select-option
              class="country-select-item"
              v-for="item in Object.keys(CURRENCY_MAPPER)"
              :key="item.toLowerCase()"
              :value="item"
            >
              <svg class="icon" aria-hidden="true">
                <use
                  :xlink:href="'#icon-guoqi-' + item.toLowerCase()"
                ></use></svg>{{ CURRENCY_MAPPER[item].host }}
            </a-select-option>
          </artmisads-select>
          <div class="filters-name">Brands</div>
          <a-auto-complete
            class="brand-autocomplete"
            :allowClear="true"
            @clear="clearBrands"
            @keyup="searchBrands"
            @select="selectbrand"
            @blur="brandBlur"
            :options="brandSuggestions"
            placeholder="Please enter..."
            v-model:value="filters.brandKeywords"
          />
          <div class="filters-name">Commission Min</div>
          <div class="commission-input">
            <artmisads-input-number
              min="0"
              max="100"
              suffix="%"
              :step="0.1"
              class="width100"
              v-model:value="filters.commission"
              :formatter="(value) => `${value}`"
              placeholder="Please enter..."
              @change="setCommission"
            />
          </div>
        </div>
        <div class="btns">
          <a
            @click="reset"
            :class="'reset' + (filtersNum ? '' : ' disabled')"
            href="javascript:;"
          >
            <span style="margin-right: 3px">Reset</span
            ><span class="filter-num" v-show="filtersNum">{{ filtersNum }}</span
            ><span style="margin-left: 3px">Filters</span>
          </a>
          <artmisads-button class="save" type="primary" @click="updateFilters"
            >Confirm</artmisads-button
          >
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script setup>
import { publisherApi } from '~/server'
import { PRICE } from '~/contants/publisher'
import { ref, defineEmits, defineProps } from 'vue'
import { CURRENCY_MAPPER } from '../../../contants/common'

const { init } = defineProps(['init'])
const emit = defineEmits(['updateFilters'])
const filters = ref({ ...init, brandKeywords: '' })

let options = []
let filtersNum = ref(0)
let filtersDown = ref()
let visible = ref(false)
let filtersWrapper = ref()
const brandSelected = ref(false)
const brandSuggestions = ref([])
let filtersOrigi = {
  brandName: '',
  countryCode: 'All',
  commission: undefined,
}

function updateFilters() {
  visible.value = false
  emit('updateFilters', filters.value)
}

function setCountry(country) {
  let isMark = options.includes('countryCode')

  if (!isMark && country != filtersOrigi['countryCode']) {
    options.push('countryCode')
    filtersNum.value++
  } else {
    if (isMark && country == filtersOrigi['countryCode']) {
      options = options.filter((i) => i != 'countryCode')

      if (filtersNum) {
        filtersNum.value--
      }
    }
  }

  filters.value.countryCode = country
}

function setCommission(v) {
  if (filtersOrigi.commission != v && v) {
    if (!options.includes('commission')) {
      filtersNum.value += 1
      options.push('commission')
    }
  } else {
    if (options.includes('commission')) {
      filtersNum.value -= 1
      options = options.filter((i) => i != 'commission')
    }
  }
}

function brandBlur() {
  if (!brandSelected.value) {
    reset(false)
  }
}

function reset(updateList = true) {
  if (filtersNum.value) {
    options = []
    filtersNum.value = 0
    visible.value = !updateList
    brandSelected.value = false
    filters.value.brandName = ''
    filters.value.commission = null
    filters.value.countryCode = 'All'
    filters.value.brandKeywords = ''

    if (updateList) {
      emit('updateFilters', filters.value)
    }
  }
}

function clearBrands() {
  filtersNum.value -= 1
  brandSelected.value = false
  brandSuggestions.value = ''
  filters.value.brandName = ''
  filters.value.brandKeywords = ''
  options = options.filter((i) => i != 'brandName')
  getBrandsList('')
}

function searchBrands(e) {
  const v = e.target.value.trim()

  brandSelected.value = false

  getBrandsList(v)
}

function getBrandsList(keywords) {
  publisherApi.getBrandsSuggestion(keywords).then((res) => {
    if (res.success) {
      brandSuggestions.value = res.data.map((item) => {
        return { label: item.brandName, value: item.brandName }
      })
    }
  })
}

getBrandsList('')

function selectbrand(name) {
  brandSelected.value = true
  filters.value.brandName = name

  if (!options.includes('brandName')) {
    filtersNum.value++
    options.push('brandName')
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/filter-popup.less';
@import '../../../assets/less/country-select.less';

.filters-down:deep(.ant-input-number-handler-wrap) {
  opacity: 1 !important;
}
</style>