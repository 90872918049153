<template>
  <div class="line-chart-modal" ref="lineChartModalRef">
    <artmisads-modal
      :open="true"
      :title="props.title"
      :width="1200"
      :getContainer="() => lineChartModalRef"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
      :onCancel="props.onCancleModal"
    >
      <a-spin :spinning="spinning">
        <div class="content">
          <div class="sp-head-content" v-if="props.pageType === 'sellerProduct' || props.pageType === 'publisherProduct' || props.pageType === 'publisherLink'">
            <span v-if="!props.itemInfo.productImageUrl"></span>
            <img v-else :src="props.itemInfo.productImageUrl" />
            <div class="text">
              <p>{{props.itemInfo.productTitle}}</p>
              <div class="flex">
                <span>{{props.itemInfo.productAsin}}</span>
                <span v-if="props.pageType === 'sellerProduct' && props.itemInfo.productBrand">Brand: {{ props.itemInfo.productBrand }}</span>
                <span v-if="props.pageType === 'sellerProduct'">Marketplace: {{ props.marketplace }}</span>
              </div>
            </div>
          </div>
          <div class="brand-head-content" v-else-if="props.pageType === 'sellerBrand' || props.pageType === 'publisherBrand'">
            <span v-if="!props.itemInfo.brandLogoImageUrl"></span>
            <img v-else :src="props.itemInfo.brandLogoImageUrl" />
            <div class="text">
              <p>{{props.itemInfo.brandName}}</p>
            </div>
          </div>
          <div class="publisher-head-content" v-else-if="props.pageType === 'sellerPublisher'">
            <span v-if="!props.itemInfo.publisherHeadImageUrl"></span>
            <img v-else :src="props.itemInfo.publisherHeadImageUrl" />
            <div class="text">
              <p>{{props.itemInfo.publisherName}}</p>
            </div>
          </div>
        </div>
        <div class="date">
          <artemisads-range-picker
            :allowClear="false"
            :value="hackValue || dateParams"
            @openChange="onOpenChange"
            :presets="RANGE_PRESETS"
            :disabled-date="disabledDate"
            @change="onChangeDate"
            @calendarChange="onCalendarChange"
          />
        </div>
        <div class="tags">
          <filterTag
            :setParams="setParams"
            :tagNumParams="tagNumParams"
            :marketplace="props.marketplace"
            :noNeedList="props.noNeedList"
            :defaultMetricName="props.defaultMetricName || 'clicks'"
          />
        </div>
        <div class="modal-chart">
          <chart
            :showDate="true"
            :dataList="dataList"
            :width="1152"
            showYName="metricValue"
            :isPercent="params.metricName === 'acosAmount' || params.metricName === 'conversionsRate'"
            showXName="reportDateTimestamp"
            :lineColor="chartParams.lineColor"
            :lineBgOffsetTop="chartParams.lineBgOffsetTop"
            :lineBgOffsetZero="chartParams.lineBgOffsetZero"
            :showCurrent="false"
            :startTimestamp="params.startReportDateTimestamp"
            :endTimestamp="params.endReportDateTimestamp"
            :onClickBarChart="onClickBarChart"
            :metricName="params.metricName"
          />
        </div>
     </a-spin>
    </artmisads-modal>
  </div>
</template>
<script setup>
  import moment from 'moment';
  import { ref, onMounted, computed, watch } from "vue";

  import { RANGE_PRESETS } from '~/contants/reports';
  import { reportsApi, publisherReportsApi } from '~/server/index';
  import { RESPORTS_TYPE_MAP, RESPORTS_TEST_DATA } from '~/contants/reports';
  import filterTag from '~/components/reports/filterTag.vue';
  import chart from '~/components/reports/chart.vue';
  const props = defineProps(["defaultMetricName", "linkMd5", "noNeedList", "showlineChartModal", "title", "itemInfo", "onCancleModal", "pageType", "title", "marketplace"]);

  const spinning = ref(false);
  const lineChartModalRef = ref();
  const dates = ref();
  const hackValue = ref();

  const dataList = ref([]);
  const params = ref({
    marketplace: 'amazon.com',
    startReportDateTimestamp:moment().subtract(30, 'day').valueOf(),
    endReportDateTimestamp: moment().valueOf(),
    brandId: null,
    productId: null,
    metricName: props.defaultMetricName || 'clicks',
  })

  const dateParams = ref([
    moment().subtract(30, 'day'), moment()
  ])

  const tagNumParams = ref({
    totalClicks: null,
    totalDetailPageViews: null,
    totalAddToCart: null,
    totalConversions: null,
    totalSalesAmount: null,
    totalDiscountedSalesAmount: null,
    totalBonusAmount: null,
    totalCommissionAndFeeAmount: null,
    lastUpdateAt: null
  });

  const chartParams = computed(() => {
    let lineColor, lineBgOffsetTop, lineBgOffsetZero, chartParamsData, itemData;
    itemData =  RESPORTS_TYPE_MAP[params.value.metricName];
    lineColor = itemData.lineColor;
    lineBgOffsetTop = itemData.lineBgOffsetTop;
    lineBgOffsetZero = itemData.lineBgOffsetZero;
    chartParamsData = { lineColor, lineBgOffsetTop, lineBgOffsetZero }
    return chartParamsData;
  })

  const disabledDate = current => {
      let notDate = false, tooLate = false, tooEarly = false;
    notDate = current.valueOf() > moment().valueOf();
    if (dates.value && dates.value.length) {
      tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 365;
      tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 365;
    }
    return notDate || tooEarly || tooLate;
  };

  const getList = async () => {
    spinning.value = true;
    let newparams = {...params.value}, res;
    newparams.marketplace = props.marketplace;
    if (newparams.metricName === 'acosAmount') {
      newparams.metricName = 'acos'
    }
    if (props.pageType === 'sellerProduct') {
      newparams.productId = props.itemInfo.productId;
      res = await reportsApi.getSellerCommissionReportsProductDailyMetric(newparams);
    } else if (props.pageType === 'sellerBrand') {
      newparams.brandId = props.itemInfo.brandId;
      res = await reportsApi.getSellerCommissionReportsBrandDailyMetric(newparams);
    } else if (props.pageType === 'sellerPublisher') {
      newparams.publisherId = props.itemInfo.publisherId;
      res = await reportsApi.getSellerCommissionReportsPublisherDailyMetric(newparams);
    } else if (props.pageType === 'publisherProduct') {
      newparams.productId = props.itemInfo.productId;
      res = await publisherReportsApi.getPublisherCommissionReportsProductDailyMetric(newparams);
    } else if (props.pageType === 'publisherBrand') {
      newparams.brandId = props.itemInfo.brandId;
      res = await publisherReportsApi.getPublisherCommissionReportsBrandDailyMetric(newparams);
    } else if (props.pageType === 'publisherLink') {
      newparams.trackingLinkMd5 = props.linkMd5;
      res = await publisherReportsApi.getPublisherCommissionReportsPerformanceDailyMetric(newparams);
    }
    const { success, data } = res;

    if (success) {
      dataList.value = newparams.metricName === 'acos' || newparams.metricName === 'conversionsRate' ? data.map(item => {
        return {
          ...item,
          metricValue: (item.metricValue * 100).toFixed(2).replace(/(\.\d*?[1-9])0+$|\.0+$/, '$1')
        }
      }) : data;
    }

    spinning.value = false;
  }

  const onOpenChange = open => {
    if (open) {
      dates.value = [];
      hackValue.value = [];
    } else {
      hackValue.value = undefined;
    }
  };
  const onCalendarChange = val => {
    dates.value = val;
  };

  const setParams = obj => {
    const newObj = {...params.value, ...obj};
    params.value = newObj;
  }


  const onChangeDate = val => {
    if (val) {
      dateParams.value = val;
      const dateTime = {
        startReportDateTimestamp: val[0].valueOf(),
        endReportDateTimestamp: val[1].valueOf(),
      };
      let newparams = {...params.value, ...dateTime};
      params.value = newparams;
    } else {
      dateParams.value = [];
    }
  }

  const getSellerCommissionReportsAgg = async () => {
    let newparams = {...params.value}, res;
    newparams.marketplace = props.marketplace;
    if (props.pageType === 'sellerProduct') {
      newparams.productId = props.itemInfo.productId;
    } else if (props.pageType === 'sellerBrand') {
      newparams.brandId = props.itemInfo.brandId;
    } else if (props.pageType === 'sellerPublisher') {
      newparams.publisherId = props.itemInfo.publisherId;
    } else if (props.pageType === 'publisherProduct') {
      newparams.productId = props.itemInfo.productId;
    } else if (props.pageType === 'publisherBrand') {
      newparams.brandId = props.itemInfo.brandId;
    } else if (props.pageType === 'publisherLink') {
      newparams.trackingLinkMd5 = props.linkMd5;
    }
    if (props.pageType.match('seller')) {
      res = await reportsApi.getSellerCommissionReportsAgg(newparams);
    } else if (props.pageType.match('publisher')) {
      res = await publisherReportsApi.getPublisherCommissionReportsAgg(newparams);
    }
    const { success, data } = res;
    if (success) {
      tagNumParams.value = data;
    }
  }

  watch(() => params.value.metricName, async () => {
    getList();
  });
  watch([() => params.value.startReportDateTimestamp, () => params.value.endReportDateTimestamp], async () => {
    getList();
    getSellerCommissionReportsAgg();
  });

  onMounted(() => {
    getList();
    getSellerCommissionReportsAgg();
  })

</script>
<style lang="less" scoped>
.line-chart-modal {
  .publisher-head-content,
  .brand-head-content,
  .sp-head-content {
    padding: 8px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    box-shadow: var(--disabled-box-shadow-5);
    > img {
      height: 72px;
      flex: 0 0 72px;
      position: relative;
      border-radius: 8px;
      object-fit: contain;
    }
    > span {
      width: 72px;
      height: 72px;
      min-width: 72px;
      border-radius: 8px;
      background: var(--bg-gray-2);
    }
    .flex {
      gap: 20px;
      display: flex;
    }
  }
  .sp-head-content {
    .text {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      > p {
        max-width: 1050px;
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 22px;
        white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
      span {
        padding: 4px 8px;
        border-radius: 6px;
        box-sizing: border-box;
        background: var(--bg-gray-8);

        font-weight: 500;
        font-size: 12px;
        color: var(--dark-2);
        letter-spacing: 0.3px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
  }
  .publisher-head-content,
  .brand-head-content {
    .text {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      > p {
        max-width: 1050px;
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 22px;
        white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
    }
  }
  .date {
    margin-top: 40px;
  }
  .tags {
    padding: 16px 0;
  }
}
</style>
