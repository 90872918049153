<template>
  <div class="links" ref="zjdgx">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-lianjie"></use>
      </svg>
    </breadcrumbs>
    <div class="content-wrapper">
      <h2>
        &nbsp;<a-dropdown :getPopupContainer="() => $refs.zjdgx">
          <template #overlay>
            <artmisads-dropdown-menu @click="handleMenuClick">
              <a-menu-item key="all">Export All</a-menu-item>
              <a-menu-item key="filter">Export with Filters</a-menu-item>
            </artmisads-dropdown-menu>
          </template>
          <artmisads-button-special>
            <svg class="icon export" aria-hidden="true">
              <use xlink:href="#icon-daochu"></use>
            </svg>
            <span class="btn-text">Export</span>
            <svg class="icon artmisads-select-down-icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-xia-cu"></use>
            </svg>
          </artmisads-button-special>
        </a-dropdown>
      </h2>
      <div class="divider">
        <a-divider />
      </div>
      <div class="top-filter">
        <top-pagination
          :pagination="{
            total: linksCount,
            size: filter.size,
            current: filter.page,
          }"
          @pageChange="(page) => setFilter('page', page)"
        />
        <div class="flex-wrapper">
          <a-input-search
            allow-clear
            class="search"
            placeholder="Search..."
            v-model:value="filter.keywords"
            @search="(v) => setFilter('keywords', v)"
            @change="(e) => setFilter('keywords', e)"
          />
          <FilterOptions
            @updateFilters="(data) => setFilter('popup', data)"
            :init="filter"
          />
          <artmisads-select
            class="with-sort-icon"
            v-model:value="filter.sort"
            style="width: 160px"
            @change="(option) => setFilter('sort', option)"
          >
            <a-select-option value="%2BbestSellers"
              >Best Sellers</a-select-option
            >
            <a-select-option value="-newlyAdded">Newly Added</a-select-option>
            <a-select-option value="-commission">Commission</a-select-option>
          </artmisads-select>
        </div>
      </div>
      <a-spin :spinning="isLoadingList">
        <artmisads-table
          :columns="columns"
          :data-source="links"
          :scroll="{ x: 1600 }"
          :pagination="linksCount > filter.size ? pagination : false"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'productId'">
              <a
                target="_blank"
                class="product-name"
                :href="'/publisher/products/' + record.productId"
              >
                <div
                  :class="
                    'product-img-show' + (record.imageUrl ? '' : ' show-bg')
                  "
                >
                  <img :src="record.imageUrl" alt="" v-if="record.imageUrl" />
                </div>
                <div class="product-title">
                  <span>{{ record.title }}</span>
                  <div class="asin">
                    <a-tooltip
                      v-if="record.status == 'inactive'"
                      :getPopupContainer="() => $refs.zjdgx"
                    >
                      <template #title>
                        This product has been set to an inactive status by the
                        Seller. Please contact the Seller in a timely manner to
                        confirm during the promotion.
                      </template>
                      <i class="iconfont icon-a-warring-tianchong3" />
                    </a-tooltip>
                    {{ record.asin }} -
                    {{
                      CURRENCY_MAPPER[record.countryCode]?.host || 'amazon.com'
                    }}
                    - {{ record.brandName }}
                  </div>
                </div>
              </a>
            </template>
            <template v-else-if="column.key === 'deals'">
              <productDealsColumn
                :record="item"
                v-for="item in record.productDeals"
                :key="item.id"
              />
            </template>
            <template v-else-if="column.key === 'price'">
              <price :record="record" />
            </template>
            <template v-else-if="column.key === 'commission'">
              <span :class="'fw500 commission ' + record.status"
                >{{ record.commission }}%</span
              >
            </template>
            <template v-else-if="column.key === 'source'">
              <div class="source">
                <svg
                  v-if="PLATFORM_LIST.indexOf(record.source) != -1"
                  class="icon"
                  aria-hidden="true"
                >
                  <use
                    :xlink:href="`#icon-${record.source.toLowerCase()}`"
                  ></use>
                </svg>
                <svg v-else class="icon lianjie" aria-hidden="true">
                  <use xlink:href="#icon-lianjie"></use>
                </svg>
                <span>{{ record.source }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'options'">
              <div class="options">
                <p
                  :class="
                    'short-link' +
                    (record.shortLinkSelect ? ' short-link-selected' : '')
                  "
                >
                  <span class="flex-wrapper"
                    ><i
                      :class="`iconfont icon-${
                        record.shortLinkSelect ? 'duigou-jia' : 'error3-'
                      }cu`"
                    ></i
                    >Short Link</span
                  >
                </p>
              </div>
            </template>
            <template v-else-if="column.key === 'estPayout'">
              <div class="est-payout fw500">
                {{ record.currencySymbol
                }}{{ Number(record.estPayout).toFixed(2) }}
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <div class="actions">
                <a-tooltip>
                  <template #title>Copy</template>
                  <i
                    class="copy iconfont icon-copy2"
                    @click="copyLink(record)"
                  />
                </a-tooltip>
                <i
                  class="copy iconfont icon-chart"
                  @click="showChartModal(record)"
                />
                <a-tooltip>
                  <template #title>Edit</template>
                  <i
                    class="copy iconfont icon-bianji"
                    @click="editLink(record, index)"
                  />
                </a-tooltip>
              </div>
            </template>
          </template>
        </artmisads-table>
      </a-spin>
    </div>
    <LinkToolModal
      v-model:open="openRef"
      @callback="onSaveCallback"
      :trackLinkId="editLinkIndex != -1 ? links[editLinkIndex]?.id : null"
      :productId="editLinkIndex != -1 ? links[editLinkIndex]?.productId : null"
      :countryCode="
        editLinkIndex != -1 ? links[editLinkIndex]?.countryCode : null
      "
    />
    <lineChartModal
      v-if="showlineChartModal"
      :linkMd5="linkMd5"
      title="Link Performance"
      :itemInfo="itemInfo"
      :onCancleModal="onCancleChartModal"
      pageType="publisherLink"
      :marketplace="marketplace"
      defaultMetricName="detailPageViews"
      :noNeedList="['clicks', 'acosAmount', 'bonusAmount', 'commissionAndFeeAmount']"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment'
import { message } from 'ant-design-vue'
import FilterOptions from './filters.vue'
import Price from '~/components/Price.vue'
import { publisherApi } from '../../../server'
import breadcrumbs from '../../seller/breadcrumbs.vue'
import { CURRENCY_MAPPER } from '~/contants/common.js'
import { PLATFORM_LIST } from '~/contants/publisher.js'
import LinkToolModal from '../products/detail/linkToolModal.vue'
import TopPagination from '~/components/TopPagination.vue'
import productDealsColumn from '~/components/productDealsColumn.vue'
import lineChartModal from '~/components/reports/lineChartModal.vue';

const marketplace = ref('');
const linkMd5 = ref('');
const showlineChartModal = ref(false);
const itemInfo = ref();
const openRef = ref(false)
const editLinkIndex = ref(-1)
const links = ref([])
const filter = ref({
  page: 1,
  size: 10,
  price: 'All',
  keywords: '',
  maxPrice: '',
  minPrice: '',
  currency: '$',
  brandName: '',
  commission: '',
  countryCode: 'All',
  availability: 'all',
  sort: '-newlyAdded',
})
const linksCount = ref(0)
const isLoadingList = ref(true)
const pagination = ref({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
  onChange: (page) => {
    filter.value.page = page
    getLinkList()
  },
})
const breadcrumbsData = {
  title: 'My Product Links',
  hasTitleIcon: true,
  color: '#3ebe79',
  des: 'Review all of your active product links!',
  links: [
    {
      name: 'Home',
      path: '/publisher/products',
    },
    {
      name: 'Product Links',
      path: '/publisher/links',
    },
  ],
}
const columns = [
  {
    title: 'Product',
    key: 'productId',
    dataIndex: 'productId',
    fixed: 'left',
    width: 400,
  },
  {
    title: 'Deals',
    key: 'deals',
    width: 340,
  },
  { title: 'Price', key: 'price', dataIndex: 'price', width: 200 },
  {
    title: 'Commission',
    key: 'commission',
    dataIndex: 'commission',
    width: 120,
  },
  {
    title: 'Est. Payout',
    key: 'estPayout',
    dataIndex: 'estPayout',
    width: 120,
  },
  { title: 'Source', key: 'source', dataIndex: 'source', width: 160 },
  {
    title: 'Options',
    key: 'options',
    dataIndex: 'amazonProfileId',
    width: 120,
  },
  { title: 'Action', key: 'action', width: 120, fixed: 'right' },
]

const showChartModal = (data) => {
  showlineChartModal.value = true;
  let newData = { ...data };
  newData.productTitle = data.title;
  newData.productAsin = data.asin;
  newData.productImageUrl = data.imageUrl;
  itemInfo.value = newData;
  linkMd5.value = newData.linkMd5;
  marketplace.value = CURRENCY_MAPPER[data.countryCode].host;
}

const onCancleChartModal = () => {
  showlineChartModal.value = false;
  itemInfo.value = undefined;
} 

function getLinkList() {
  const params = { ...filter.value }

  Object.keys(params).forEach((key) => {
    if (
      ['price', 'currency', 'availability', 'brandKeywords'].indexOf(key) !=
        -1 ||
      ['', null, undefined].indexOf(params[key]) != -1
    ) {
      delete params[key]
    }
  })

  if (params['countryCode'] == 'All') {
    delete params['countryCode']
  }

  isLoadingList.value = true

  publisherApi.getLinkList(params).then((res) => {
    links.value = res.data
    isLoadingList.value = false
    pagination.value.current = params.page

    if (filter.value.page == 1) {
      linksCount.value = res.total
      pagination.value.total = res.total
    }
  })
}

getLinkList()

function setFilter(type, option) {
  if (type == 'page') {
    filter.value.page = option
  } else {
    if (type == 'sort') {
      filter.value.sort = option
      filter.value.page = 1
    } else if (type == 'country') {
      filter.value.countryHost = option.value
      filter.value.countryCode = option.key.toUpperCase()
    } else if (type == 'keywords') {
      if (option.type) {
        if (option.type == 'click') {
          // clear event
          filter.value.page = 1
        } else {
          return
        }
      } else {
        filter.value.keywords = option
      }
    } else if (type == 'popup') {
      filter.value = {
        ...filter.value,
        brandName: option.brandName,
        commission: option.commission,
        countryCode: option.countryCode,
      }
    }

    filter.value.page = 1
  }

  getLinkList()
}

function copyLink(link) {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(
        link.shortLinkSelect && link.shortLink
          ? link.shortLink
          : link.trackingLink
      )
      .then((error) => {
        if (error) {
          message.error(error)
        } else {
          message.success('Copied Successfully')
        }
      })
  } else {
    message.warn(
      'Your brower version is too old, please update before use copy function.'
    )
  }
}

function handleMenuClick({ key }) {
  const fileName = `product-links-${moment().format('YYYYMMDDHHmmss')}.csv`

  if (key === 'all') {
    publisherApi.exportLinks(fileName, {})
  } else {
    const params = { ...filter.value }

    Object.keys(params).forEach((key) => {
      if (
        ['price', 'currency', 'availability'].indexOf(key) != -1 ||
        ['', null, undefined].indexOf(params[key]) != -1
      ) {
        delete params[key]
      }
    })

    if (params['countryCode'] == 'All') {
      delete params['countryCode']
    }

    params['sort'] = decodeURIComponent(params['sort'])

    publisherApi.exportLinks(fileName, params)
  }
}

function editLink(record, index) {
  if (record.status != 'inactive') {
    editLinkIndex.value = index
    openRef.value = true
  }
}
function onSaveCallback(data) {
  links.value[editLinkIndex.value] = {
    ...links.value[editLinkIndex.value],
    ...data,
  }
  editLinkIndex.value = -1
  openRef.value = false
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/ellipsis.less';

.links {
  h2 {
    height: 48px;
    display: flex;
    line-height: 18px;
    justify-content: space-between;
    .ant-btn {
      width: 118px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
    .export {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: var(--dark-1);
    }
    .btn-text {
      font-weight: 500;
      line-height: 20px;
      margin-right: 8px;
      align-self: center;
    }
    .jiantou {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      fill: var(--dark-3);
    }
  }
  .divider {
    margin-right: -16px;
  }
  .content-wrapper {
    display: block !important;
  }
  :deep(.ant-tooltip-inner) {
    font-size: 13px;
    padding: 6px 12px !important;
    line-height: 18px;
    min-width: 300px !important;
  }
}

.top-filter {
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: space-between;
  .flex-wrapper {
    display: flex;
    align-items: center;
  }
  .search {
    width: 200px;
    margin-right: 20px;
  }
  .filters {
    margin-right: 20px;
  }
}

.links-filters {
  padding: 16px;
  h3 {
    font-size: 14px;
    font-weight: normal;
  }
}

.product-name {
  display: flex;
  align-items: center;

  .product-title {
    flex: 1;
    margin-left: 12px;
    max-width: calc(100% - 64px);

    > span {
      display: block;
      cursor: pointer;
      max-height: 22px;
      font-weight: 500;
      overflow: hidden;
      line-height: 22px;
      white-space: nowrap;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      text-overflow: ellipsis;
      text-decoration: underline;
    }

    > div {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--dark-3);
      letter-spacing: 0.3px;
    }

    .asin {
      margin-top: 2px;
      .iconfont {
        margin-right: 2px;
        color: #f59b00;
      }
    }
  }

  .product-img-show {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    &.show-bg {
      background: var(--bg-gray-2);
    }

    img {
      border-radius: 8px;
      width: 56px;
      height: 56px;
      object-fit: contain;
    }
  }
}

.commission {
  opacity: 0.9;
  font-weight: 500;
  // &.inactive {
  //   opacity: 0.3;
  // }
}

.source {
  height: 24px;
  overflow: hidden;
  line-height: 24px;
  padding-right: 8px;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  background-color: var(--bg-gray-8);
  > svg {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 6px;
    border-radius: 4px;
    color: var(--dark-4);
    background: var(--bg-gray-8);
    &.lianjie {
      padding: 4px;
    }
  }
}

.options {
  white-space: nowrap;
  > p {
    height: 20px;
    padding: 0 6px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    background-color: var(--bg-gray-5);
    &.short-link {
      padding: 0;
      display: block;
      color: var(--dark-2);
      background: transparent;
      &-selected {
        opacity: 1;
        color: var(--dark-1);
      }
    }
    .flex-wrapper {
      border-radius: 4px;
      align-items: center;
      display: inline-flex;
      padding: 0 8px 0 4px;
      background-color: var(--bg-gray-8);
    }
    .iconfont {
      width: auto;
      height: auto;
      font-size: 14px;
      margin-right: 6px;
      color: var(--dark-3);
      &.icon-duigou-jiacu {
        color: var(--primary-text);
      }
    }
  }
}

.actions {
  display: flex;
  // &.inactive {
  //   .iconfont {
  //     opacity: 0.3;
  //     cursor: not-allowed;
  //   }
  // }
  .iconfont {
    width: 28px;
    height: 28px;
    cursor: pointer;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    border-radius: 6px;
    color: var(--dark-2);
    &:not(:last-of-type) {
      margin-right: 16px;
    }
    &:hover {
      background: var(--bg-gray-6);
    }
    &:active {
      color: var(--dark-1);
      background: var(--bg-gray-4);
    }
  }
}
</style>